import { faEye, faInfo } from '@fortawesome/free-solid-svg-icons';
import { Fragment, useEffect, useState } from 'react'
import { indexCustomers } from '../../../api/facturapi/Customers';
import TableComp from '../../Layouts/Table'
import Details from './Details';
import usePagination from '../../../hooks/usePagination';
import PaginationComp from '../../Layouts/Pagination';

const ClientsComp = () => {
	const hookClients = usePagination({ defaultPageSize: 10 });
	const { page, pageSize, totalItems } = hookClients.pagination;

	const [modalDet, setModalDet] = useState(false);

	useEffect(() => {
		const getCustomers = async () => {
			hookClients.setLoading(true);
			const { data, totalItems } = await indexCustomers({ page, pageSize });
			hookClients.setData(data);
			hookClients.setTotalItems(totalItems);
			hookClients.setLoading(false);
		}
		getCustomers();
	}, [page, pageSize]);

	const viewDetails = (item) => {
		hookClients.setDataSel(item);
		setModalDet(true);
	}

	return (
		<Fragment>
			<TableComp
				headers={['#', 'Razón social', 'RFC', 'Correo electrónico', 'Teléfono', 'Creado en', 'Acciones']}
				keys={['#', 'legal_name', 'tax_id', 'email', 'phone', 'created_at']}
				body={hookClients.data}
				loading={hookClients.loading}
				loadingItems={pageSize}
				page={page}
				actions={[
					{ icon: faInfo, handleClick: viewDetails, tooltip: 'Ver detalles de la factura', variant: 'outline-info' },
				]}
				moment_dates={[{ index: 5, format: 'LLLL' }]}
			/>
			{/* Componente de paginación */}
			<PaginationComp
				current={page}
				onChange={hookClients.changePage}
				totalItems={totalItems}
				defaultPageSize={pageSize}
			/>
			{/* Modal para ver los detalles del cliente */}
			{
				modalDet && <Details
					onClose={() => setModalDet(false)}
					customer={hookClients.dataSel}
				/>
			}
		</Fragment>
	)
}

export default ClientsComp