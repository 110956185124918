import { Fragment, useEffect } from 'react'
import { indexOrganizations } from '../../../api/facturapi/Organizations';
import TableComp from '../../Layouts/Table'
import usePagination from '../../../hooks/usePagination';
import PaginationComp from '../../Layouts/Pagination';
import { faInfo } from '@fortawesome/free-solid-svg-icons';

const OrganizationsComp = () => {
    const hookOrganizations = usePagination({ defaultPageSize: 10 });
    const { page, pageSize, totalItems } = hookOrganizations.pagination;

    useEffect(() => {
        const getOrganizations = async () => {
            hookOrganizations.setLoading(true);
            const { data, totalItems } = await indexOrganizations({ page, pageSize });
            hookOrganizations.setData(data);
            hookOrganizations.setTotalItems(totalItems);
            hookOrganizations.setLoading(false);
        }
        getOrganizations();
    }, [page, pageSize]);

    return (
        <Fragment>
            <TableComp
                headers={['#', 'Nombre', 'RFC', 'Correo electrónico', 'Régimen fiscal', 'Creación', 'Acciones']}
                keys={['#', 'legal.legal_name', 'legal.tax_id', 'legal.support_email', 'legal.tax_system', 'created_at']}
                moment_dates={[{ index: 5, format: 'LLL' }]}
                body={hookOrganizations.data}
                loading={hookOrganizations.loading}
                page={page}
                loadingItems={pageSize}
                actions={[
                    { icon: faInfo, variant: 'outline-info', tooltip: 'Detalles', handleClick: () => { } }
                ]}
            />
            <PaginationComp
                current={page}
                onChange={hookOrganizations.changePage}
                totalItems={totalItems}
                defaultPageSize={pageSize}
            />
        </Fragment>
    )
}

export default OrganizationsComp