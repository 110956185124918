import { message } from 'antd';
import FetcherFa from '../../libs/FetcherFa';

const Facturapi = require('facturapi');
const facturapi = new Facturapi(process.env.REACT_APP_TEST_SECRET_KEY);

const headers = {
    Authorization: `Bearer ${process.env.REACT_APP_TEST_SECRET_KEY}`,
}

export const indexInvoices = async ({ page, pageSize }) => {
    let response = { data: [], totalItems: 0 };
    try {
        const fetch = await facturapi.invoices.list({ page, limit: pageSize });
        response = {
            data: fetch.data,
            totalItems: fetch.total_results
        };
    } catch (error) {
        response = { data: [], totalItems: 0 };
    } finally {
        return response;
    }
}

export const storeInvoice = async (customer, items, details) => {
    let response = { status: 404, data: { message: "Ha ocurrido un error al tratar de emitir su factura, ¡Verifique sus datos!" } };
    try {
        const fetch = await FetcherFa({
            method: 'POST',
            url: '/invoices',
            headers,
            data: {
                customer,
                items,
                ...details
            }
        });
        response.data = fetch.data
        response.status = fetch.status
        return response;
    } catch (error) {
        return response;
    }
}

export const deleteInvoice = async (invoice_id, motive) => {
    let response = false;
    try {
        let fetch = await FetcherFa({
            method: 'DELETE',
            url: `/invoices/${invoice_id}`,
            headers,
            params: { motive }
        });
        if (fetch.status === 200)
            response = fetch.data
        // console.log("DELETE invoice: ", fetch);
    } catch (error) {
        response = false;
    } finally {
        return response;
    }
}

export const downloadInvoice = async (invoice_id, type) => {
    try {
        let fetch = await FetcherFa({
            url: `/invoices/${invoice_id}/${type}`,
            method: 'GET',
            headers: {
                Authorization: `Bearer ${process.env.REACT_APP_TEST_SECRET_KEY}`,
            },
            responseType: 'arraybuffer'
        });

        const url = window.URL.createObjectURL(new Blob([fetch.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `${invoice_id}.${type}`)
        document.body.appendChild(link)
        link.click();
    } catch (error) {
        // console.log("Error download: ", error);
    }
}

export const sendInvoice = async (invoice_id) => {
    let response = false;
    try {
        let fetch = await FetcherFa({
            method: 'POST',
            url: `/invoices/${invoice_id}/email`,
            headers: {
                Authorization: `Bearer ${process.env.REACT_APP_TEST_SECRET_KEY}`,
            },
        });
        if (fetch.status === 200)
            response = true;
        // console.log("Fetch send email: ", fetch);
    } catch (error) {
        // console.log("Error send email: ", error);
    } finally {
        return response;
    }
}


