const Facturapi = require('facturapi');
const facturapi = new Facturapi(process.env.REACT_APP_USER_SECRET_KEY);

export const indexOrganizations = async ({ page, pageSize }) => {
    let response = { data: [], totalItems: 0 };
    try {
        const fetch = await facturapi.organizations.list({ page, limit: pageSize });
        response = {
            data: fetch.data,
            totalItems: fetch.total_results
        }
    } catch (error) {
        response = { data: [], totalItems: 0 };
    } finally {
        return response
    }
}