import { useToast } from '@chakra-ui/react';
import { faBan, faCheck, faCheckDouble, faEye, faFileArchive, faFileCode, faFileContract, faFilePdf, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Fragment, useEffect, useState } from 'react'
import { downloadInvoice, indexInvoices, sendInvoice } from '../../../api/facturapi/Invoices';
import setToast from '../../../libs/SetToast';
import FlexButton from '../../Layouts/FlexButton';
import TableComp from '../../Layouts/Table';
import NewInvoice from '../fragments/NewInvoice';
import CancelInvoice from './CancelInvoice';
import InvoiceDetails from './Details';
import PaginationComp from '../../Layouts/Pagination';
import usePagination from '../../../hooks/usePagination';

const InvoicesComp = () => {
    const hookInvoices = usePagination({ defaultPageSize: 10 });
    const { page, pageSize, totalItems } = hookInvoices.pagination;

    const [modalDet, setModalDet] = useState(false);
    const [modalAdd, setModalAdd] = useState(false);
    const [modalCan, setModalCan] = useState(false);

    const toast = useToast();

    useEffect(() => {
        const getInvoices = async () => {
            hookInvoices.setLoading(true);
            const { data, totalItems } = await indexInvoices({ page, pageSize });
            hookInvoices.setTotalItems(totalItems);
            hookInvoices.setData(data);
            hookInvoices.setLoading(false);
        }
        getInvoices();
    }, [page, pageSize]);

    const viewDetails = (item) => {
        hookInvoices.setDataSel(item);
        setModalDet(true);
    }

    const downloadPDF = (item) => downloadInvoice(item.id, 'pdf');
    const downloadXML = (item) => downloadInvoice(item.id, 'xml');
    const downloadZip = (item) => downloadInvoice(item.id, 'zip');
    // Enviar factura al correo electrónico registrado
    const sendByEmail = (item) => {
        const response = sendInvoice(item.id);
        if (response)
            setToast({
                toast, title: '¡Operación exitosa!', status: 'success',
                description: 'Se ha enviado la factura en formato PDF y XML al correo del cliente'
            });
    }
    // Método apra cancelar la factura
    const cancelInvoice = (item) => {
        hookInvoices.setDataSel(item);
        setModalCan(true);
    }
    // Acuse de cancelación
    const cancelVoucher = (item) => {
        hookInvoices.setDataSel(item);
    }
    // Método para Verificar CDFi: Abre una pestaña en blanco
    const redirectTo = (item) => {
        window.open(item.verification_url, '_blank');
    }

    return (
        <Fragment>
            <TableComp
                headers={['#', 'ID', 'Cliente', 'Tipo', 'Moneda', 'Total', 'Estado', 'Creado en', 'Acciones']}
                keys={['#', 'id', 'customer.legal_name', 'type', 'currency', 'total', 'status', 'created_at']}
                body={hookInvoices.data}
                loading={hookInvoices.loading}
                loadingItems={pageSize}
                page={page}
                currency={[{ index: 5 }]}
                actions={[
                    { icon: faEye, handleClick: viewDetails, tooltip: 'Ver detalles de la factura', variant: 'outline-secondary' },
                    { icon: faFilePdf, handleClick: downloadPDF, tooltip: 'Descargar PDF', variant: 'outline-danger' },
                    { icon: faFileCode, handleClick: downloadXML, tooltip: 'Descargar XML', variant: 'outline-primary' },
                    { icon: faFileArchive, handleClick: downloadZip, tooltip: 'Descargar ZIP', variant: 'outline-success' },
                    { icon: faPaperPlane, handleClick: sendByEmail, tooltip: 'Enviar por correo electrónico', variant: 'outline-dark' },
                    { icon: faCheckDouble, handleClick: redirectTo, tooltip: 'Verificar CFDi', variant: 'outline-success', dinamic: [{ key: 'status', value: 'valid' }] },
                    { icon: faBan, handleClick: cancelInvoice, tooltip: 'Cancelar factura', variant: 'outline-warning', dinamic: [{ key: 'status', value: 'valid' }] },
                    { icon: faFileContract, handleClick: cancelVoucher, tooltip: 'Acuse de cancelación', variant: 'outline-info', dinamic: [{ key: 'status', value: 'canceled' }] },
                ]}
                icons={[
                    { value: 'valid', icon: <FontAwesomeIcon icon={faCheck} color='green' /> },
                    { value: 'canceled', icon: <FontAwesomeIcon icon={faBan} color='red' /> },
                ]}
                moment_dates={[{ index: 7, format: 'LLLL' }]}
            />
            {/* Componente de paginación */}
            <PaginationComp
                current={page}
                onChange={hookInvoices.changePage}
                totalItems={totalItems}
                defaultPageSize={pageSize}
            />
            {/* Agregar factura */}
            <FlexButton style={{ bottom: 20, right: 20 }} onClick={() => setModalAdd(true)} />

            {/* Modal para agregar nueva factura */}
            {
                modalAdd && <NewInvoice
                    onClose={() => setModalAdd(false)}
                    hookInvoices={hookInvoices}
                />
            }
            {/* Modal para ver los detalles de la factura */}
            {
                modalDet && <InvoiceDetails
                    onClose={() => setModalDet(false)}
                    invoice={hookInvoices.dataSel}
                />
            }
            {/* Modal para cancelar una factura */}
            {
                modalCan && <CancelInvoice
                    onClose={() => setModalCan(false)}
                    invoice={hookInvoices.dataSel}
                    hookInvoices={hookInvoices}
                />
            }
        </Fragment>
    )
}

export default InvoicesComp