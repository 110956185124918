const Facturapi = require('facturapi');
const facturapi = new Facturapi(process.env.REACT_APP_TEST_SECRET_KEY);

export const indexCustomers = async ({ page, pageSize }) => {
    let response = { data: [], totalItems: 0 };
    try {
        const fetch = await facturapi.customers.list({ page, limit: pageSize });
        return {
            data: fetch.data,
            totalItems: fetch.total_results
        }
    } catch (error) {
        return response;
    }
}